<template>
  <div style="background-color: #FAFAFA;">
    <!--专利超市-->
    <patentsupermarketPage v-if="pagename=='patent-supermarket'"></patentsupermarketPage>
    <!--列表页面-->
    <!--高校其他专利-->
    <collegesPage v-if="pagename=='colleges'"></collegesPage>
    <!--店铺列表-->
    <storeListPage v-if="pagename=='storeList'"></storeListPage>
    <!--专家店铺列表-->
    <expertsPage v-if="pagename=='experts'"></expertsPage>
    <!--第三方店铺列表-->
    <OutsideStorePage v-if="pagename=='OutsideStore'"></OutsideStorePage>
    <!--商品列表-->
    <goodListPage v-if="pagename=='goodsList'"></goodListPage>
  </div>

</template>

<script>
  import goodListPage from '@/components/patent/GoodList.vue';
  import storeListPage from '@/components/patent/storeList.vue';
  import expertsPage from '@/components/patent/experts.vue';
  import collegesPage from '@/components/patent/colleges.vue';
  import OutsideStorePage from '@/components/patent/OutsideStore';
  import patentsupermarketPage from '@/components/patent/patentSupermarket.vue';

  export default {
    name: "PatentSupermarket",
    data() {
      return {
        markethome: true,
        listIndex: 3,
        patentTypeChoice: 0,
        pagename: ''
      }
    },
    watch: {
      $route: {
        immediate: true, // 一旦监听到路由的变化立即执行
        handler(to, from) {
          if (JSON.stringify(to.params) !== '{}') {
            this.pagename = to.params.pageId
            this.patentTypeChoice = to.query.patentType
            this.selectPatentType(this.patentTypeChoice)
          }
        },
      }
    },
    mounted() {

    },
    components: {
      goodListPage,
      storeListPage,
      expertsPage,
      collegesPage,
      OutsideStorePage,
      patentsupermarketPage
    },
    methods: {

      //专利index
      selectPatentType(index) {
        this.patentTypeChoice = index
      },


    }
  }
</script>

<style scoped lang="scss">
  /deep/ .ivu-page-item-active {
    background-color: #2d8cf0;
    /*background-color: #fc7c40;*/
    color: white !important;
    border: none;
  }

  /deep/ .ivu-page-item-active a {
    color: white !important;
  }

  .iconfont {
    font-size: 13px;
    vertical-align: -0.05rem !important;
  }

  .zl_header .per_header:nth-child(3), .zl_header .per_header:nth-child(6) {
    border-right: 1px solid rgb(232, 232, 232) !important;
  }

  .left_desc {
    padding-left: 17px;

    & span {
      line-height: 30px;
    }
  }

  #order > span {
    display: inline-block;
    margin-right: 20px;
    cursor: pointer;
  }

  .per_header {
    padding: 20px;
  }

  .zl_header .per_header:nth-child(4), .zl_header .per_header:nth-child(5), .zl_header .per_header:nth-child(6) {
    margin-top: 4px !important;
  }

  .service_div {
    font-size: 14px;
    line-height: 50px;

    & span {
      display: inline-block;
      height: 50px;
      margin-right: 40px;
      cursor: pointer;
      color: #666;
    }
  }

  .rmzl_content_real {
    padding: 20px 15px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    text-align: center;
    flex-wrap: wrap;
    align-items: center;

  }

  .dpyx {
    & span {
      margin: 0 2px;
    }
  }

  .san_hang_sheng {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .liang_hang_sheng {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .si_hang_sheng {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }

  .rmzl_title_price .desc {
    text-align: left;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;;
    overflow: hidden;
  }

  .rmzl_image {
    width: 150px;
    height: 180px;
    float: left;
  }

  .rmzl_title_price {
    width: 228px;
    //height: 180px;
    line-height: 1;
    float: left;
    box-sizing: border-box;
    padding: 12px 15px!important;
  }

  .div_center {
    width: 100%;
  }

  .center {
    width: 1200px;
    margin: 0 auto;
  }

  .select_span {
    color: #FF6A00 !important;
    border-bottom: 1px solid #FF6A00;
  }

  .active {
    color: #FF6A00;
  }

  .active span:last-child {
    color: #333;
  }

  .activeASC span:first-child {
    color: #333;
  }

  .activeASC span:last-child {
    color: #FF6A00;
  }
</style>
